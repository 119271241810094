import { h } from "preact";
import { usePrerenderData } from "@preact/prerender-data-provider";
import Header from "../header";
import styles from "./style.scss";
import { useRef } from "preact/hooks";
import BackgroundTexture from "../background-texture";
import { Link } from "preact-router";

const Layout = (props) => {
  const ChildPage = props.childPage;
  const [data, loading, __error] = usePrerenderData({
    ...props,
    url: props.url.split("?", 1)[0],
  });
  const globalRef = useRef();

  if (!globalRef.current && data) {
    globalRef.current = {
      logos: data.logos,
      social: data.social,
      footer: data.footer,
    };
  }

  return (
    <div className={styles.layout}>
      <Link className={styles.epk} href="/epk" />
      <BackgroundTexture
        loaded={!!data}
        // style={{
        //   backgroundColor: globalRef.current
        //     ? globalRef.current.logos.backgroundColor.hex
        //     : "#fff",
        // }}
        image={data ? data.background : null}
      />
      {data && globalRef.current && (
        <Header
          path={props.path}
          indicator={globalRef.current.logos.pageIndicator}
          logo={data.menuLogo || globalRef.current.logos.menuLogo}
          scrollIcon={data.scrollIcon || globalRef.current.logos.scrollIcon}
          shopLink={globalRef.current.social.shopLink}
          social={globalRef.current.social}
          background={data.menuBackground || data.background}
        />
      )}
      {data && globalRef.current && (
        <ChildPage
          shopLink={globalRef.current.social.shopLink}
          pageData={data}
          social={globalRef.current.social}
          footer={globalRef.current.footer}
        />
      )}
      {/* Render a large div to keep scrollbar between page shifts */}
      {loading && props.path !== "/contact" && (
        <div style={{ height: "120vh" }} />
      )}
    </div>
  );
};

export default Layout;
