import { h } from "preact";

const Bandcamp = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 97.5 97.5"
      version="1.1"
      preserveAspectRatio="xMidYMid"
    >
      <g>
        <path
          d="M48.875,0C21.882,0,0,21.882,0,48.875S21.882,97.75,48.875,97.75S97.75,75.868,97.75,48.875S75.868,0,48.875,0z
		 M64.835,70.857H12.593l20.32-43.965h52.244L64.835,70.857z"
        />
      </g>
    </svg>
  );
};

export default Bandcamp;
