import { h } from "preact";
import styles from "./style.scss";
import { useEffect, useRef } from "preact/hooks";
import classNames from "classnames";

const BackgroundTexture = ({ image, loaded, style, className }) => {
  const imgRef = useRef();

  const setBackground = () => {
    if (typeof document !== "undefined") {
      document.documentElement.style.backgroundSize = "contain";
      document.documentElement.style.backgroundImage = `url("${src}")`;
    }
  };

  let src = null;
  if (imgRef.current && !loaded) {
    src = imgRef.current.src;
    setBackground(src);
  } else if (image) {
    src = image.localSrc;
    setBackground(src);
  }

  return (
    <div />
    // <img
    //   src={src}
    //   ref={imgRef}
    //   alt=""
    //   // style={style}
    //   // style={{
    //   //   backgroundColor: globalRef.current
    //   //     ? globalRef.current.logos.backgroundColor.hex
    //   //     : "#fff",
    //   // }}
    //   className={classNames(className, styles.background)}
    // />
  );
};

export default BackgroundTexture;
