import { h } from "preact";
import { Link } from "preact-router";
import styles from "./style.scss";
import { useContext, useEffect, useState } from "preact/hooks";
import classNames from "classnames";
import { Resize } from "../../context/contexts";
import Bandcamp from "../svg/bandcamp";
import Insta from "../svg/insta";
import Facebook from "../svg/facebook";
import Apple from "../svg/apple";
import Spotify from "../svg/spotify";
import Songkick from "../svg/songkick";

const Indicator = ({ shown, indicator }) => {
  return (
    <img
      className={classNames(styles.indicator, {
        [styles["indicator--shown"]]: shown,
      })}
      src={indicator.localSrc}
      alt=""
    />
  );
};

const Header = (props) => {
  const { path, logo, indicator, shopLink, scrollIcon, social, background } =
    props;

  const { height, width } = useContext(Resize);
  const hideHeader = path === "/" || path === "/epk";
  const [headerOpen, setHeaderOpen] = useState(!hideHeader);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const openHeight = hideHeader ? height * 1.25 : -300;
  const isMobile = width < 600;

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [path]);

  const __scrollHide = useEffect(() => {
    let lastScroll = 0;

    const onScroll = () => {
      const currentScroll = window.scrollY;
      const end = document.body.scrollHeight - window.innerHeight;
      if (currentScroll < openHeight) {
        setHeaderOpen(false);
      } else if (currentScroll > lastScroll && !(currentScroll < 1)) {
        setHeaderOpen(false);
      } else if (currentScroll < lastScroll && !(currentScroll > end - 1)) {
        setHeaderOpen(true);
      }
      lastScroll = currentScroll;
    };

    onScroll();
    if (window.scrollY >= openHeight) {
      setHeaderOpen(true);
    }

    window.addEventListener("scroll", onScroll, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", onScroll, {
        capture: false,
        passive: true,
      });
    };
  }, [openHeight, isMobile]);

  const mobileHeaderEnabled = !["/", "/epk"].includes(path);

  let bgStyle = {
    backgroundColor: "unset",
  };
  if (mobileMenuOpen) {
    if (background) {
      bgStyle = {
        backgroundSize: "contain",
        backgroundImage: `url(${background.localSrc})`,
      };
    } else {
      bgStyle = {
        backgroundColor: "white",
      };
    }
  }

  return (
    <header
      className={classNames(styles.header, {
        [styles["header--mobileOpen"]]: mobileMenuOpen,
        [styles["header--closed"]]: !headerOpen,
      })}
    >
      <ul className={styles.header__navlist} style={bgStyle}>
        <li
          className={classNames(styles.header__item, {
            [styles["header__item--active"]]: path === "/listen",
          })}
        >
          <Indicator indicator={indicator} shown={path === "/listen"} />
          <Link href="/listen">listen</Link>
        </li>
        <li
          className={classNames(styles.header__item, {
            [styles["header__item--active"]]: path === "/watch",
          })}
        >
          <Indicator indicator={indicator} shown={path === "/watch"} />
          <Link href="/watch">watch</Link>
        </li>
        <li className={styles.header__item}>
          <a target="_blank" rel="noopener noreferrer" href={shopLink}>
            shop
          </a>
        </li>
        <li
          className={`${styles.header__item} ${styles["header__item--logo"]}`}
        >
          <Link href="/">
            <img src={logo.localSrc} alt="In The Pines Logo" />
          </Link>
        </li>
        <li
          className={classNames(styles.header__item, {
            [styles["header__item--active"]]: path === "/tour",
          })}
        >
          <Indicator indicator={indicator} shown={path === "/tour"} />
          <Link href="/tour">tour</Link>
        </li>
        <li
          className={classNames(styles.header__item, {
            [styles["header__item--active"]]: path === "/contact",
          })}
        >
          <Indicator indicator={indicator} shown={path === "/contact"} />
          <Link href="/contact">contact</Link>
        </li>
        <li
          className={classNames(styles.header__item, {
            [styles["header__item--active"]]: path === "/archive",
          })}
        >
          <Indicator indicator={indicator} shown={path === "/archive"} />
          <Link href="/archive">archive</Link>
        </li>
        <li className={styles.mobileIcons}>
          <a href={social.instagram} target="_blank" rel="noopener noreferrer">
            <Insta className={styles.mobileIcons__icon} />
          </a>
          <a href={social.facebook} target="_blank" rel="noopener noreferrer">
            <Facebook className={styles.mobileIcons__icon} />
          </a>
          <a href={social.bandcamp} target="_blank" rel="noopener noreferrer">
            <Bandcamp className={styles.mobileIcons__icon} />
          </a>
          <a href={social.apple} target="_blank" rel="noopener noreferrer">
            <Apple className={styles.mobileIcons__icon} />
          </a>
          <a href={social.spotify} target="_blank" rel="noopener noreferrer">
            <Spotify className={styles.mobileIcons__icon} />
          </a>
          <a href={social.songkick} target="_blank" rel="noopener noreferrer">
            <Songkick className={styles.mobileIcons__icon} />
          </a>
        </li>
        <div
          className={classNames(styles.mobileHeader, {
            [styles["mobileHeader--closed"]]:
              !headerOpen || mobileMenuOpen || !mobileHeaderEnabled,
          })}
        >
          {mobileHeaderEnabled && (
            <Link
              className={classNames(styles.mobileTitle, {
                [styles["mobileTitle--hidden"]]: mobileMenuOpen,
              })}
              href="/"
            >
              <img src={logo.localSrc} alt="In The Pines Logo" />
            </Link>
          )}
          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className={classNames(styles.mobileMenuToggle)}
            aria-label="Open Menu"
          >
            <img alt="Open Menu" src={scrollIcon.localSrc} />
          </button>
        </div>
      </ul>
    </header>
  );
};

export default Header;
