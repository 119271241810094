import { h } from "preact";
import { Router } from "preact-router";
import { Provider } from "@preact/prerender-data-provider";

// Code-splitting is automated for `routes` directory
import { useEffect, useState } from "preact/hooks";
import Layout from "./layout";
import styles from "./style.scss";
import Home from "../routes/home";
import { Resize } from "../context/contexts";
import { debounce } from "lodash";
import Watch from "../routes/watch";
import Listen from "../routes/listen";
import Itpww from "../routes/itpww";
import Tour from "../routes/tour";
import Contact from "../routes/contact";
import Epk from "../routes/epk";

const App = (props) => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const onResize = () => {
      const ww = window.innerWidth;
      const wh = window.innerHeight;

      const vh = wh * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      setWindowSize({ width: ww, height: wh });
    };

    const dbOnResize = debounce(onResize, 100);

    window.addEventListener("resize", dbOnResize, false);
    onResize();

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vhstatic", `${vh}px`);

    // TODO cleanup
    return () => {
      window.removeEventListener("resize", dbOnResize, false);
    };
  }, []);

  return (
    <Provider value={props}>
      <Resize.Provider value={windowSize}>
        <div id="app" className={styles.app}>
          <Router>
            <Layout path="/" childPage={Home} />
            <Layout path="/epk" childPage={Epk} />
            <Layout path="/watch" childPage={Watch} />
            <Layout path="/listen" childPage={Listen} />
            <Layout path="/tour" childPage={Tour} />
            <Layout path="/archive" childPage={Itpww} />
            <Layout path="/contact" childPage={Contact} />
          </Router>
        </div>
      </Resize.Provider>
    </Provider>
  );
};

export default App;
