import { h } from "preact";

const Apple = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 56 56"
      version="1.1"
      preserveAspectRatio="xMidYMid"
    >
      <g clipPath="url(#clip0_230_512)">
        <path
          d="M50.8482 43.6416C50.0013 45.5981 48.9989 47.399 47.8375 49.0548C46.2543 51.312 44.958 52.8744 43.9591 53.7421C42.4105 55.1662 40.7513 55.8956 38.9745 55.9371C37.699 55.9371 36.1608 55.5741 34.3702 54.8378C32.5738 54.105 30.9229 53.7421 29.4134 53.7421C27.8302 53.7421 26.1323 54.105 24.3161 54.8378C22.4972 55.5741 21.0319 55.9578 19.9116 55.9958C18.2078 56.0684 16.5096 55.3183 14.8144 53.7421C13.7325 52.7984 12.3792 51.1807 10.758 48.8889C9.01861 46.4415 7.58858 43.6036 6.46827 40.3681C5.26845 36.8734 4.66699 33.4893 4.66699 30.2131C4.66699 26.4602 5.47793 23.2233 7.10222 20.5109C8.37877 18.3321 10.077 16.6135 12.2026 15.3518C14.3281 14.0901 16.6247 13.4471 19.0979 13.406C20.4512 13.406 22.2259 13.8246 24.4312 14.6473C26.6304 15.4727 28.0424 15.8913 28.6615 15.8913C29.1244 15.8913 30.693 15.4019 33.3522 14.4261C35.867 13.5211 37.9894 13.1464 39.7281 13.294C44.4395 13.6742 47.9792 15.5315 50.3332 18.8776C46.1195 21.4307 44.0351 25.0066 44.0766 29.594C44.1146 33.1672 45.4109 36.1406 47.9584 38.5015C49.113 39.5973 50.4023 40.4442 51.8368 41.0457C51.5257 41.9478 51.1974 42.812 50.8482 43.6416ZM40.0427 1.12093C40.0427 3.92157 39.0195 6.53652 36.98 8.95689C34.5189 11.8342 31.542 13.4969 28.3138 13.2345C28.2727 12.8986 28.2488 12.5449 28.2488 12.1733C28.2488 9.48473 29.4192 6.60738 31.4977 4.25476C32.5354 3.06359 33.8552 2.07314 35.4556 1.28305C37.0526 0.504739 38.5632 0.0743181 39.9839 0.000610352C40.0254 0.375012 40.0427 0.749438 40.0427 1.12089V1.12093Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_230_512">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Apple;
