import { h } from "preact";

const Facebook = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 310 310"
      version="1.1"
      preserveAspectRatio="xMidYMid"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M310 155C310 69.3958 240.604 0 155 0C69.3958 0 0 69.3958 0 155C0 232.364 56.681 296.489 130.781 308.117V199.805H91.4258V155H130.781V120.852C130.781 82.0047 153.922 60.5469 189.327 60.5469C206.28 60.5469 224.023 63.5742 224.023 63.5742V101.719H204.479C185.225 101.719 179.219 113.668 179.219 125.938V155H222.207L215.335 199.805H179.219V308.117C253.319 296.489 310 232.364 310 155Z"
      />
    </svg>
  );
};

export default Facebook;
